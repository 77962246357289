import { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTrans } from "../../../hooks/useTrans";
import { useAuthAction } from "../../../hooks";
import { AuthApi, User } from "../../../apis";
import {
    AuthLoginCheckPayload,
    AuthLoginCheckResponse,
} from "../../../apis/entities/dtos";
import { loginPageSchema } from "./schema";
import {
    Button,
    Divider,
    HStack,
    Input,
    VStack,
    Text,
    Image,
    InputGroup,
    InputRightElement,
    Box,
    useToast,
    Center,
} from "@chakra-ui/react";
import { AppFormControl, AppIcon, AppLoader } from "../../../components";
import { cPath } from "../../../config";
import logoSrc from "../../../assets/images/logo.svg";

export const LoginPage = () => {
    // hooks
    const { t } = useTrans();
    const navigate = useNavigate();
    const toast = useToast();
    const { setAuthTokenAction, loginAction } = useAuthAction();
    const hostNotFound = new URLSearchParams(document.location.search).get(
        "HNF"
    );

    // state & const
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // form
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
        reset,
    } = useForm<AuthLoginCheckPayload>({
        resolver: yupResolver(loginPageSchema(t)),
    });

    const onSubmitHandler = (formData: AuthLoginCheckPayload) => {
        setLoading(true);
        AuthApi.loginCheck<AuthLoginCheckResponse, AuthLoginCheckPayload>(
            formData
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null && response.token) {
                    const { token } = response;
                    setAuthTokenAction(token);
                    AuthApi.me<User>().then(
                        ({ response: res, errorMessage: errMsg }) => {
                            if (errMsg) {
                                toast({
                                    title: errMsg,
                                    status: "error",
                                });
                            } else if (res !== null) {
                                loginAction(res);
                                navigate(cPath("ADMIN.DASHBOARD_PAGE"));
                                reset();
                            }
                        }
                    );
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (hostNotFound) {
            toast({
                title: t("ppub.Security.LoginPage:toast.hostNotFound", {
                    host: hostNotFound,
                }),
                status: "error",
            });
        }
    }, [hostNotFound]);

    if (loading) {
        return <AppLoader />;
    }

    return (
        <>
            <Box
                maxW={"28rem"}
                py={{ base: 8, sm: 8 }}
                mx={"auto"}
                mt={{ base: 0, sm: 8 }}
            >
                <Center>
                    <Image w={"60%"} src={logoSrc} alt="IMS" loading="lazy" />
                </Center>
            </Box>
            <Box
                maxW={"28rem"}
                py={{ base: 8, sm: 8 }}
                px={{ base: 4, sm: 10 }}
                borderRadius={"0.5rem"}
                boxShadow={"var(--chakra-colors-boxShadowPrimary)"}
                mx={"auto"}
                bg={"var(--chakra-colors-white)"}
            >
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <VStack gap={6} alignItems={"stretch"}>
                        <VStack gap={5} alignItems={"stretch"}>
                            <AppFormControl
                                label={t("ent.User:email.label")}
                                isInvalid={!!errors.username}
                                message={errors.username?.message}
                            >
                                <Input
                                    type="email"
                                    placeholder={t(
                                        "ent.User:email.placeholder"
                                    )}
                                    {...register("username")}
                                />
                            </AppFormControl>
                            <AppFormControl
                                label={t("ent.User:plainPassword.label")}
                                isInvalid={!!errors.password}
                                message={errors.password?.message}
                            >
                                <InputGroup>
                                    <Input
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        placeholder={t(
                                            "ent.User:plainPassword.placeholder"
                                        )}
                                        {...register("password")}
                                    />
                                    <InputRightElement>
                                        <Text
                                            cursor={"pointer"}
                                            onClick={togglePasswordVisibility}
                                            mr={2}
                                        >
                                            {showPassword ? (
                                                <AppIcon
                                                    name={"icl-eye"}
                                                    w="1.25rem"
                                                />
                                            ) : (
                                                <AppIcon
                                                    name={"icl-eye-slash"}
                                                    w="1.25rem"
                                                />
                                            )}
                                        </Text>
                                    </InputRightElement>
                                </InputGroup>
                            </AppFormControl>
                        </VStack>
                        <HStack
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            {/*
                            <Checkbox size={"sm"}>
                                <Text textStyle={"semiBoldSm"}>
                                    Remember me
                                </Text>
                            </Checkbox>
                            */}
                            <NavLink
                                to={cPath(
                                    "PUBLIC.SECURITY.FORGOT_PASSWORD_PAGE"
                                )}
                            >
                                <Text textStyle={"semiBoldSm"}>
                                    {t(
                                        "ppub.Security.LoginPage:text.forgotPassword"
                                    )}
                                </Text>
                            </NavLink>
                        </HStack>
                        <VStack gap={6} alignItems={"stretch"}>
                            <Button
                                isDisabled={isSubmitting || loading}
                                type="submit"
                            >
                                {t("ppub.Security.LoginPage:button.login")}
                            </Button>
                            <HStack>
                                <Divider
                                    borderColor={
                                        "var(--chakra-colors-primaryT80)"
                                    }
                                />
                                <Text whiteSpace={"nowrap"}>
                                    {t("ppub.Security.LoginPage:text.newUser")}
                                </Text>
                                <Divider
                                    borderColor={
                                        "var(--chakra-colors-primaryT80)"
                                    }
                                />
                            </HStack>
                            <Button as={NavLink} to={""} variant={"secondary"}>
                                {t(
                                    "ppub.Security.LoginPage:button.createAccount"
                                )}
                            </Button>
                        </VStack>
                    </VStack>
                </form>
            </Box>
        </>
    );
};
