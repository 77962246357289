import { default as i18next } from "i18next";
import { initReactI18next } from "react-i18next";

import translationEn from "./en.json";
import translationFr from "./fr.json";

export const initTranslation = (locale = "en") => {
    i18next.use(initReactI18next).init({
        resources: {
            en: {
                translation: translationEn,
            },
            fr: {
                translation: translationFr,
            },
        },
        lng: locale,
        fallbackLng: "en",
        keySeparator: ":", // means no nested json keys
        nsSeparator: "::", // avoid use of namespace (default :), otherwise it will break key after :
        interpolation: {
            escapeValue: true,
        },
        react: {
            useSuspense: true, // Enable Suspense mode
        },
    });
};
