import { useTrans } from "./useTrans";
import { useAuthData } from "../contexts";
import { cPath } from "../config";
import { NavItem } from "../containers/AppNav/AppNav";

export const useNavItems = (type: string = "main"): NavItem[] => {
    const { t } = useTrans();
    const { grant } = useAuthData();

    const userNavItems: NavItem[] = [
        {
            label: t("nav:profile"),
            url: cPath("ADMIN.PROFILE_PAGE"),
            iconName: "icl-address-card",
        },
        {
            label: t("nav:logout"),
            url: cPath("ADMIN.LOGOUT"),
            iconName: "icl-account-logout",
        },
    ];

    const mainNavItems: NavItem[] = [
        {
            label: t("nav:dashboard"),
            url: cPath("ADMIN.DASHBOARD_PAGE"),
            iconName: "icl-view-quilt",
            divider: true,
        },
    ];

    if (grant.isSuperAdmin) {
        mainNavItems.push({
            label: t("nav:administration"),
        });

        mainNavItems.push({
            label: t("nav:user"),
            url: cPath("ADMIN.USER_PAGE"),
            iconName: "icl-id-badge",
        });
    }

    return type === "user" ? userNavItems : mainNavItems;
};
