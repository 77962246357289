import { CSSProperties, FC } from "react";

type AppIconProps = {
    name: string;
    w?: string;
    h?: string;
    color?: string;
    customAttribute?: string;
    style?: CSSProperties;
};

export const AppIcon: FC<AppIconProps> = ({
    name,
    w = "1.25rem",
    h,
    color = "currentColor",
    style = {},
}) => {
    /*
    const iconType = name.startsWith("icl-")
        ? "linear.svg"
        : name.startsWith("ics-")
          ? "solid.svg"
          : name.startsWith("ica-")
            ? "app.svg"
            : "duotone.svg";
    */
    return (
        <svg
            className={`ic-icon ${name}`}
            focusable="false"
            width={w}
            height={h || w}
            color={color}
            style={style}
        >
            <use xlinkHref={`#${name}`}></use>
        </svg>
    );
};
