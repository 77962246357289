import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Canceler } from "axios";
import { Checkbox, HStack, useDisclosure, useToast } from "@chakra-ui/react";
import { DataType, IKaTableProps, SortingMode } from "ka-table";
import { Column } from "ka-table/models";
import { useAppTable, useFormContainerState, useTrans } from "../../../hooks";
import {
    AppOptionBoxItem,
    AppOptionsBox,
    KaPageSizeSelector,
} from "../../../components";
import { User as Entity, UserApi as EntityApi } from "../../../apis";
import { schema } from "./schema";
import { APPC } from "../../../config";
import { ICellTextProps } from "ka-table/props";

export const useUserCrud = () => {
    // hooks
    const { t } = useTrans();
    const toast = useToast();
    const formContainerState = useFormContainerState();

    const {
        table,
        pageIndex,
        pagesCount,
        pageSize,
        sortColumn,
        sortOrder,
        setPagesCount,
        getSelectRow,
        setSelectRow,
        getSelectAll,
        setSelectAll,
        // getSelectTotal,
    } = useAppTable("firstName");

    const { handleSubmit, register, formState, reset } = useForm<Entity>({
        resolver: yupResolver(schema(t)),
        mode: "onSubmit",
    });

    // state & const
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingList, setLoadingList] = useState(true);
    const [list, setList] = useState<Entity[]>([]);
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);
    const [data, setData] = useState<Entity>(new Entity());
    const deleteAlertState = useDisclosure();
    const [actionSelectId, setActionSelectId] = useState(0);

    // List
    const columns: Column[] = [
        {
            key: "selection-cell",
            isFilterable: false,
            width: 50,
        },
        {
            key: "firstName",
            title: t("ent.User:name.label"),
            dataType: DataType.String,
        },
        {
            key: "email",
            title: t("ent.User:email.label"),
            dataType: DataType.String,
        },
        {
            key: "userType",
            title: t("ent.User:userType.label"),
            dataType: DataType.String,
            isSortable: false,
        },
        {
            key: "action",
            title: "",
            style: { textAlign: "right" },
            dataType: DataType.Object,
            width: 50,
            isSortable: false,
        },
    ];

    const paging = {
        enabled: true,
        pageSizes: APPC.PAGE_SIZES,
        pageSize,
        pageIndex,
        pagesCount,
    };

    const SelectionCell = ({ rowKeyValue }: ICellTextProps) => (
        <Checkbox
            isChecked={getSelectRow(pageIndex, rowKeyValue)}
            onChange={() => setSelectRow(pageIndex, rowKeyValue)}
        />
    );

    const SelectionHeader = () => {
        return (
            <Checkbox
                isChecked={getSelectAll(pageIndex) === "ALL"}
                isIndeterminate={getSelectAll(pageIndex) === "SOME"}
                onChange={() => setSelectAll(pageIndex)}
            />
        );
    };

    const kaTableProps: IKaTableProps = {
        table: table,
        columns: columns,
        data: list || [],
        loading: {
            enabled: loadingList,
        },
        paging: paging,
        rowKeyField: "id",
        sortingMode: SortingMode.SingleRemote,
        childComponents: {
            pagingSizes: {
                content: (props) => <KaPageSizeSelector {...props} />,
            },
            cellText: {
                content: (props: ICellTextProps) => {
                    switch (props?.column?.key) {
                        case "action":
                            return (
                                <HStack justify={"flex-end"} gap={3}>
                                    <AppOptionsBox
                                        options={filterActionOptions(props)}
                                        data={props?.rowData}
                                        onClick={actionClickHandler}
                                    />
                                </HStack>
                            );
                        case "selection-cell":
                            return <SelectionCell {...props} />;
                    }
                },
            },
            headCell: {
                content: (props) => {
                    if (props.column.key === "selection-cell") {
                        return <SelectionHeader />;
                    }
                },
            },
        },
    };

    const deleteHandler = async () => {
        if (actionSelectId < 1) {
            return;
        }
        setLoadingList(true);
        await EntityApi.deleteItem(actionSelectId)
            .then(({ errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else {
                    fetchListData();
                }
            })
            .finally(() => setLoadingList(false));
    };

    const actionOptions: AppOptionBoxItem[] = [
        {
            key: "edit",
            label: t("cmn:option.edit"),
            iconName: "icl-pencil",
        },
        {
            key: "delete",
            label: t("cmn:option.delete"),
            iconName: "icl-trash",
        },
    ];

    const filterActionOptions = (props: ICellTextProps) => {
        return actionOptions.map((o) => {
            if (o.key === "delete") {
                const isDisabled =
                    props?.rowData?.userType === APPC.BE.User.ROLE_SUPER_ADMIN;
                o.isDisabled = isDisabled;
            }
            return o;
        });
    };

    const actionClickHandler = (key: string, data?: Entity) => {
        if (!data) {
            return;
        }
        switch (key) {
            case "edit":
                formContainerState.open(data?.id);
                break;
            case "delete":
                setActionSelectId(data?.id);
                deleteAlertState.onOpen();
                break;
        }
    };

    const fetchListData = async () => {
        setLoadingList(true);
        const params = {
            itemsPerPage: pageSize,
        };
        params[`order[${sortColumn}]`] = sortOrder;
        if (search) {
            params["firstName"] = search;
        }

        await EntityApi.getCollection<Entity>(pageIndex + 1, params, (c) => {
            cancelTokenSourcesRef.current.push(c);
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setPagesCount(Math.ceil(response.totalItems / pageSize));
                    setList(response.items);
                }
            })
            .finally(() => setLoadingList(false));
    };

    useEffect(() => {
        if (!formContainerState.isOpen) {
            fetchListData();
        }
    }, [
        pageIndex,
        pageSize,
        sortColumn,
        sortOrder,
        search,
        formContainerState.isOpen,
    ]);

    // Form
    const onSubmitHandler = (formData: Entity) => {
        setLoading(true);
        EntityApi.createOrUpdate<Entity, Entity>(
            formContainerState.entityId,
            formData
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t("cmn:message.save.success"),
                        status: "success",
                    });
                    reset();
                    formContainerState.close();
                }
            })
            .finally(() => setLoading(false));
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        handleSubmit(onSubmitHandler)();
    };

    useEffect(() => {
        reset();
        setData(new Entity());
        if (!formContainerState.entityId) {
            return;
        }
        setLoading(true);
        EntityApi.getItem<Entity>(formContainerState.entityId)
            .then(({ errorMessage, isNotFound, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (isNotFound) {
                    toast({
                        title: t("cmn:message.entityNotFound"),
                        status: "error",
                    });
                } else if (response !== null) {
                    reset();
                    setData(response);
                }
            })
            .finally(() => setLoading(false));
    }, [formContainerState.entityId]);

    return {
        formContainerState,
        kaTableProps,
        actionOptions,
        actionClickHandler,
        deleteHandler,
        deleteAlertState,
        setSearch,
        onFormSubmit,
        register,
        formState,
        data,
        loading,
    };
};
